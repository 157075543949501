import Send from '../../cp.client.js'

export default {
  getCallActionHandler (params) {
    return Send({
      url: '/cp/api/call-action-handler',
      method: 'get',
      params: params
    })
  }
}

import Send from '../trans.client.js'
import commonUtils from '@/plugins/commonUtils'

export default {
  // SR 임력시 초기 데이터
  getSrInitData () {
    return new Promise((resolve, reject) => {
      Send({
        url: '/trans/sr/init-data',
        method: 'get',
        params: {}
      }).then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  postSrInquery (formData) {
    return new Promise((resolve, reject) => {
      Send({
        url: '/trans/sr',
        method: 'post',
        data: formData
      }).then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
          commonUtils.screenToPdfUpload(formData, 'postSrInquery')
        })
    })
  },
  putSrInquery (formData) {
    return new Promise((resolve, reject) => {
      Send({
        url: '/trans/sr',
        method: 'put',
        data: formData
      }).then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
          commonUtils.screenToPdfUpload(formData, 'putSrInquery')
        })
    })
  },
  putSrCancel (formData) {
    return Send({
      method: 'put',
      url: '/trans/sr/cancel',
      data: formData
    }).catch((err) => {
      console.log(err)
      commonUtils.screenToPdfUpload(formData, 'putSrCancel')
    })
  },
  getTemplateInfo (srRno) {
    return new Promise((resolve, reject) => {
      Send({
        url: '/trans/sr/' + srRno + '/template',
        method: 'get'
      }).then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getSrInqueryInfo (srRno) {
    return new Promise((resolve, reject) => {
      Send({
        url: '/trans/sr/' + srRno,
        method: 'get'
      }).then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getSrInqueryReqInfo (srRno) {
    return new Promise((resolve, reject) => {
      Send({
        url: '/trans/sr/' + srRno + '/req',
        method: 'get'
      }).then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  //S/R Inquiry > Pick up 장소 선택 팝업
  getSrPickUpPlace (formData) {
    return Send({
        url: '/trans/sr/pick-up-place',
        method: 'get',
        params: {
            selectRow: formData.selectRow || '0',
            hidPolCtrCode: formData.hidPolCtrCode,
            cntrSize: formData.cntrSize || '40',
            cntrType: formData.cntrType || 'GP',
            pickUpDate: formData.pickUpDate,
            hidPolPortCode: formData.hidPolPortCode //테스트용 - 실제론 세션 사용
        }
    })
  },
  //SHA 제약 체크
  getBkgCancelCd (srRno, bkgNo) {
    return Send({
      url: '/trans/sr/cancel-possible/',
      method: 'get',
      params: {
        srNo: srRno,
        bkgNo: bkgNo
      }
    })
  },
  postNextPartBlNo (srNo) {
    return new Promise((resolve, reject) => {
      Send({
        url: '/trans/sr/next-part-blno',
        method: 'post',
        data: { srNo: srNo }
      }).then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getSrContainerFileInfo (reqRno, bkgNo, dgType) {
    return new Promise((resolve, reject) => {
      Send({
        url: '/trans/sr/container-file-list/' + reqRno + '/' + bkgNo + '/' + dgType,
        method: 'get'
      }).then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getVgmContainerInfo (bkgNo) {
    return new Promise((resolve, reject) => {
      Send({
        url: '/trans/sr/vgm-cntr/' + bkgNo,
        method: 'get'
      }).then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getActShpr (porPlcCd, dlyPlcCd, etd, podPortCd) {
    return new Promise((resolve, reject) => {
      Send({
        url: '/trans/sr/fix-cargo/actual-shipper-cst-list/' + porPlcCd + '/' + dlyPlcCd + '/' + etd + '/' + podPortCd,
        method: 'get'
      }).then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getCollectFrtAppNoHcnee (formData) {
    return Send({
        url: '/trans/sr/collect-hcnee/',
        method: 'post',
        data: formData
    })
  },
  getBkgShpr (formData) {
    return Send({
        url: '/trans/sr/bkg-shpr',
        method: 'get',
        params: formData
    })
  },
  checkCnSrBkgShipCodeArea (polPortCd) {
    return Send({
        url: '/trans/sr/cn-sr-booking-ship-code',
        method: 'get',
        params: {
          polPortCd: polPortCd
        }
    })
  }
}
